import { useLocation } from "@remix-run/react";
import { useEffect } from "react";
import type { CurrentUser } from "@fscrypto/domain";
import { identify, page, load } from "./tracking";
import { setUser } from "@sentry/browser";

export const useTrackingInitialize = (currentUser?: CurrentUser) => {
  const location = useLocation();

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    currentUser && identify(currentUser);
    // Set Sentry User
    const user = {
      id: currentUser?.id,
      email: currentUser?.email,
      username: currentUser?.username,
    };

    setUser(currentUser ? user : null);
  }, [currentUser]);

  useEffect(() => {
    page(location.pathname);
  }, [location.pathname]);
};
