import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";
import "./styles/tailwind.css";
import "./styles/markdown.css";
import "./styles/sonner.css";
import "@fontsource-variable/inter/wght.css";
import "@fontsource-variable/dm-sans/wght.css";

import { MutationCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink } from "@trpc/react-query";
import { useState } from "react";
import { trpc } from "~/utils/trpc";
import { type ClientEnv, clientEnv } from "./.server/env";
import { createRequestCaller } from "./.server/router";
import { Toaster } from "./components/ui/sonner";
import { Header } from "@fscrypto/flip-kit/header";
import { Footer } from "@fscrypto/flip-kit/footer";
import { TooltipProvider } from "./components/ui/tooltip";
import styles from "./styles/tailwind.css?url";
import { toast } from "sonner";
import superjson from "superjson";

import { registerCustomType, useTypedRouteLoaderData } from "remix-typedjson";
import { Decimal } from "decimal.js";
import { useTrackingInitialize } from "~/utils/tracking-initialize";
import type { CurrentUser } from "@fscrypto/domain";
import * as Sentry from "@sentry/remix";

// Update the SuperJSON configuration
superjson.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toString(),
    deserialize: (v) => new Decimal(v),
  },
  "decimal.js",
);
// Register Decimal.js with remix-typedjson
registerCustomType({
  type: "decimal",
  is: (value: unknown) => Decimal.isDecimal(value),
  serialize: (value: Decimal) => value.toString(),
  deserialize: (value: string) => new Decimal(value),
});

declare global {
  interface Window {
    ENV: ClientEnv;
  }
}

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: styles }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const caller = await createRequestCaller(request);
  const me = await caller.public.me();

  return {
    user: me,
    ENV: clientEnv,
  };
};

export function Layout({ children }: { children: React.ReactNode }) {
  // Needs to be useRouteLoaderData because it might be undefined in the error boundary (https://github.com/remix-run/remix/issues/8951#issuecomment-1973321870)
  const rootLoader = useRouteLoaderData<typeof loader>("root");

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (error) => {
            toast.error(error.message);
          },
        }),
      }),
  );

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [httpLink({ url: "/earn/trpc", transformer: superjson })],
    }),
  );

  return (
    <html lang="en" className="bg-bg-primary dark">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* load rudderstack data plane through cloudflare worker */}
        <script src="https://rsp-production.flipsidecrypto.workers.dev/dataPlane" async />
        {/*  Google tag (gtag.js) */}
        <script src="https://www.googletagmanager.com/gtag/js?id=G-WHSDN11PZ7" async />
        <script src="/googleAnalytics.js" />
        <Meta />
        <Links />
      </head>
      <body className="bg-bg-primary">
        <TooltipProvider delayDuration={0}>
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              {children}
              <Toaster />
            </QueryClientProvider>
          </trpc.Provider>
        </TooltipProvider>
        <ScrollRestoration />
        <script
          // biome-ignore lint/security/noDangerouslySetInnerHtml: setting env vars
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(rootLoader?.ENV ?? {})}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  const rootLoader = useTypedRouteLoaderData<typeof loader>("root");
  const currentUser = rootLoader?.user as CurrentUser | undefined;
  useTrackingInitialize(currentUser);

  return (
    <>
      <Header
        user={rootLoader?.user}
        onLogout={() => {
          if (window.rudderanalytics) {
            // this makes sure the session and anonymous id are reset when a user is logging out
            window.rudderanalytics.reset([true]);
          }
        }}
      />
      <Outlet />
      <Footer />
    </>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  // Only capture non-route errors in Sentry
  if (!isRouteErrorResponse(error)) {
    Sentry.captureException(error);
  }

  return (
    <html lang="en" className="bg-bg-primary dark">
      <head>
        <title>Error!</title>
        <Meta />
        <Links />
      </head>
      <body className="bg-bg-primary">
        <TooltipProvider delayDuration={0}>
          <Header
            user={undefined}
            onLogout={() => {
              if (window.rudderanalytics) {
                window.rudderanalytics.reset([true]);
              }
            }}
          />
          <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            {isRouteErrorResponse(error) && error.status === 404 ? (
              <>
                <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
                <p className="text-xl text-gray-600 mb-8">Oops! Page not found</p>

                <p className="text-gray-500 mb-8">
                  The page you are looking for might have been removed or is temporarily unavailable.
                </p>
              </>
            ) : (
              <>
                <h1 className="text-6xl font-bold text-gray-800 mb-4">Error</h1>
                <p className="text-xl text-gray-600 mb-8">Oops! Something went wrong</p>
                <p className="text-gray-500 mb-8">
                  {error instanceof Error ? error.message : "An unexpected error occurred"}
                </p>
              </>
            )}
            <button
              type="button"
              onClick={() => {
                if (window.history.length > 2) {
                  window.history.back();
                } else {
                  window.location.href = "/";
                }
              }}
              className="px-6 py-3 bg-bg-secondary text-fg-primary rounded-lg hover:bg-bg-secondary/80 transition-colors"
            >
              Go Back
            </button>
          </div>
          <Footer />
        </TooltipProvider>
        <Scripts />
      </body>
    </html>
  );
}
